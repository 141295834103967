
import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class DocumentCard extends Vue {
    @Prop({ default: true }) padding!: boolean;
    @Prop({ default: false }) empty!: boolean;
    @Prop({ default: false }) dragging!: boolean;
    @Prop({ default: "Hubr" }) theme!: "Hubr" | "My Hubr";

    show: boolean = false;

    get clickOutsideConfig() {
        return {
            handler: this.closeDatepicker,
            isActive: this.show,
            capture: true,
        };
    }

    get hasSettingsOptions() {
        return !!this.$slots["settings-options"];
    }

    closeDatepicker() {
        this.show = false;
    }
}
