var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"aspect-w-1 aspect-h-1 border rounded-md group cursor-pointer transition-colors",class:{
        'border-office-accent-100 hover:border-office-accent-500': _vm.theme === 'My Hubr',
        'border-secondary-100 hover:border-secondary-500': _vm.theme === 'Hubr',
        'hover:border-dashed': _vm.empty,
        'border-secondary-500': _vm.dragging,
    }},[_c('div',{staticClass:"flex flex-col"},[_c('div',_vm._g({staticClass:"w-full flex-1 flex items-center justify-center border-b border-secondary-100 transition-colors max-h-[calc(100%-50px)]",class:{
                'p-[16px]': _vm.padding,
                'border-office-accent-100': _vm.theme === 'My Hubr',
                'border-secondary-100': _vm.theme === 'Hubr',
                'text-office-accent-300 group-hover:border-dashed hover:border-office-accent-500': _vm.empty && _vm.theme === 'My Hubr',
                'text-secondary-300 group-hover:border-dashed hover:border-secondary-500': _vm.empty && _vm.theme === 'Hubr',
                'text-office-500 bg-office-accent-100 bg-opacity-50': !_vm.empty && _vm.theme === 'My Hubr',
                'text-secondary-500 bg-secondary-100 bg-opacity-50': !_vm.empty && _vm.theme === 'Hubr',
            }},_vm.$listeners),[_vm._t("content")],2),_c('div',{staticClass:"flex flex-row text-14"},[_c('div',{staticClass:"whitespace-nowrap p-[16px] overflow-hidden overflow-ellipsis w-full flex-1",class:{ 'text-gray-500 text-center': _vm.empty }},[_vm._t("title")],2),(_vm.hasSettingsOptions)?_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.clickOutsideConfig),expression:"clickOutsideConfig"}],staticClass:"relative"},[_c('div',{staticClass:"p-[16px] group hover:bg-secondary-100 hover:bg-opacity-50 cursor-pointer w-[50px] flex items-center justify-center flex-none transition-colors",on:{"click":function($event){$event.stopPropagation();_vm.show = !_vm.show}}},[_c('icon-dots-vertical',{staticClass:"h-4"})],1),(_vm.show)?_c('div',{staticClass:"absolute border border-secondary-100 right-[8px] top-[42px] bg-white rounded-md z-10",on:{"click":function($event){$event.stopPropagation();}}},[_vm._t("settings-options")],2):_vm._e()]):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }